<template>
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-subheader ">
            <div class="d-flex align-items-center">
                <div class="mr-auto">
                    <h3 class="m-subheader__title m-subheader__title--separator">
                        Trail
                    </h3>
                    <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                        <li class="m-nav__item m-nav__item--home">
                            <router-link exact :to="{ name: 'home' }" class="m-nav__link m-nav__link--icon">
                                <i class="m-nav__link-icon la la-home"></i>
                            </router-link>
                        </li>
                        <li class="m-nav__separator">
                            -
                        </li>
                        <li class="m-nav__item">
                            <router-link exact :to="{ name : 'trail' }" class="m-nav__link"><span class="m-nav__link-text">Trail</span>
                            </router-link>
                        </li>
                    </ul>
                </div>

            </div>
        </div>



        <div class="m-content">

            <div class="kt-portlet kt-portlet--height-fluid">
                <div class="kt-portlet__head">

                    <div class="kt-portlet__head-toolbar">
                        <ul class="nav nav-pills nav-pills-sm nav-pills-label nav-pills-bold" role="tablist">
                            <li class="nav-item">
                                <router-link :to="{ name: 'listAll', params: { id: trailID }}" class="nav-link ">
                                    All
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'listPoint', params: { id: trailID }}" class="nav-link ">
                                    Route
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'listPoi', params: { id: trailID }}" class="nav-link ">
                                    POI in-route
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'listNotRoute', params: { id: trailID }}" class="nav-link ">
                                    POI Not in Route
                                </router-link>
                            </li>

                            <li class="nav-item">
                                <router-link :to="{ name: 'listBus', params: { id: trailID }}" class="nav-link">
                                    BUS
                                </router-link>
                            </li>

                        </ul>
                    </div>
                </div>

            </div>


            <div class="m-portlet m-portlet--mobile">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">
                                Bus MAP
                                <small>
                                                                                                                Map of all bus
                                                                                                            </small>
                            </h3>
                        </div>
                    </div>
                    <div class="m-portlet__head-tools">
                    </div>
                </div>
                <div class="m-portlet__body">
                    <!--begin: Search Form -->
                    <div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
                        <div class="row align-items-center">
                            <!-- <div class="col-xl-8 order-2 order-xl-1">
                                                                                    <div class="form-group m-form__group row align-items-center">
                                                                                        <div class="col-md-4">
                                                                                            <div class="m-input-icon m-input-icon--left">
                                                                                                <input type="text" class="form-control m-input m-input--solid" v-model="search" placeholder="Search..." id="generalSearch">
                                                                                                <span class="m-input-icon__icon m-input-icon__icon--left">
                                                                                                                            <span>
                                                                                                                                <i class="la la-search"></i>
                                                                                                                            </span>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div> -->
                            <!-- <div class="col-xl-4 order-1 order-xl-2 m--align-right">
                                                                                        <button v-b-modal="'newPointModal'" class="btn btn-brand m-btn m-btn--custom m-btn--icon m-btn--air m-btn--pill">
                                                                                                                <span>
                                                                                                                    <i class="la la-plus"></i>
                                                                                                                    <span>Add Points</span>
                                                                                                                </span>
                                                                                                            </button>
                                                                                    </div> -->
                        </div>
                    </div>
                    <div class="alert alert-success alert-dismissible" role="alert" v-if="alertCreated">
                        <button type="button" class="close" aria-label="Close" @click="alertCreated=false"></button> New trail created successfully
                    </div>
                    <div class="alert alert-success alert-dismissible" role="alert" v-if="alertUpdated">
                        <button type="button" class="close" aria-label="Close" @click="alertUpdated=false"></button> Trail updated successfully
                    </div>
                    <div id="map"></div>

                    <b-container>
                        <!--begin::Form-->
                        <form class="m-form m-form--label-align-right">
                            <div class="m-portlet__body">
                                <div class="m-form__content">
                                    <div class="m-alert m-alert--icon alert alert-danger" role="alert" v-if="error" id="m_form_1_msg">
                                        <div class="m-alert__icon">
                                            <i class="la la-warning"></i>
                                        </div>
                                        <div class="m-alert__text" v-html="message">
                                        </div>

                                    </div>
                                </div>
                                <div class="m-form__section m-form__section--first">
                                    <div class="form-group m-form__group row">
                                        <label class="col-lg-2 col-form-label">
                                            Input geojson
                                                                                                </label>
                                        <div class="col-lg-9">
                                            <textarea class="form-control m-input" v-model="json" placeholder="..."></textarea>
                                            <input type="hidden" title="about-sub_title" required="required" v-model="trailID" class="form-control m-input">
                                            <span class="m-form__help">-Please input geojson taken from page http://geojson.io/ as this following example:<br/>
"coordinates": [<br/>
          [<br/>
            106.69441223144531,<br/>
            10.77367815586651<br/>
          ],<br/>
          [<br/>
            106.69617176055907,<br/>
            10.774858602342633<br/>
          ],<br/>
          [<br/>
            106.69310331344604,<br/>
            10.7773881149201<br/>
          ]<br/>
        ]<br/>
-Must copy exactly the same structure as our sample data<br/>
-Each time submitting a new geojson, all the new points will added to the end of the current list<br/>
-For the purpose of correct display, please make sure all POIs' longitude and latitude are in the intended trail with points<br/>
-To see the the overview map, admin need to insert at least all the points and POI or either POI not-in-route to active the overview map</span>


                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>

                        <div class="col-xl-12 order-1 order-xl-2 m--align-right">

                            <button type="button" class="btn btn-brand " @click="modifyPoint">{{ 'Add new Bus Point(s)'}}</button>
                        </div>



                        <!--end::Form-->
                    </b-container>


                </div>
                <div class="table-responsive">
                <table class="table table-inverse" v-if="locations.length > 0">
                    <thead>
                        <tr>
                            <th>#</th>



                            <th>Coordinates</th>

                            <th>Trail</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="location,index in this.locations">
                            <td>{{index + 1}}</td>

                            <td>lat: {{ location.lat}} <br> long: {{ location.long }}
                            </td>

                            <td>{{ location.trail.name }}</td>
                            <td>
                                <span class="m-badge m-badge--wide {activeClass}" v-bind:class="[ location.active ? 'm-badge--success' : 'm-badge--danger' ]">{{ location.active ? "Active" : "Inactive" }}
                                                                                </span>
                            </td>
                            <td style="width: 100px;">
                                <!-- <button title="Edit details" class="btn m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill" v-b-modal="'newLocationModal'" @click="updateLocation(location.id)">
                                                                                    <i class="la la-edit"></i>
                                                                                </button> -->
                                <button title="Delete" @click="deleteLocation(location.id, location.name)" class="btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill">
                                                                                    <i class="la la-trash"></i>
                                                                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
                <b-pagination v-if="total > 0 && total > pageSize" align="right" size="md" :total-rows="total" v-model="page" :per-page="pageSize">
                </b-pagination>
            </div>

            <location-modal @clear-id="clearId" @clicked-reload="getList" @show-create-success-alert="showCreateSuccessAlert" @show-update-success-alert="showUpdateSuccessAlert" v-bind:locationId="locationId" />

            <loading :active.sync="isLoading"
                     :z-index="99999"
                     :can-cancel="false"
                     :is-full-page="true"></loading>

            <!-- <MglMap id="map-container" :accessToken="accessToken" :mapStyle="mapStyle" :center="coordinates" @load="onMapLoaded" /> -->
        </div>
    </div>
</template>

<style>
    #map {
        height: 500px;
        width: 100%;
    }
</style>


<script>
    import LocationApi from '../../api/location';
    import LocationModal from './PointModal';
    // Import component
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';


    export default {
        components: {
            LocationModal,
            Loading

        },

        mounted: function() {},
        name: "listpoint",
        data() {
            return {
                fullPage: true,
                isLoading: false,
                error: false,
                alertCreated: false,
                alertUpdated: false,
                message: null,
                page: 1,
                total: 0,
                search: '',
                pageSize: 1500,
                locationId: null,
                locations: [],
                json: '',
                points: [],
                trailID: '',
                createdAlert: "New location created successfully",
                updatedAlert: "Location updated successfully"
            }
        },
        watch: {


        },
        beforeMount() {

            var id = this.$route.params.id;
            this.trailID = id;
            this.getList()
        },
        methods: {

            updateLocation(id) {
                this.locationId = id;
            },
            deleteLocation(id, name) {
                let approve = confirm(`Do you want to remove BUS POINT ?`);

                if (approve !== false) {
                    LocationApi.removeLocation(this, id, (context) => {

                        this.getList();
                        this.alertCreated = false;
                        this.alertUpdated = false;
                    });
                }
            },
            updatePageSize(val) {
                this.pageSize = parseInt(val);
            },
            clearId() {
                this.locationId = null;
                this.getList();
            },
            showCreateSuccessAlert() {
                this.alertCreated = true;
                this.alertUpdated = false;
            },
            showUpdateSuccessAlert() {
                this.alertCreated = false;
                this.alertUpdated = true;
            },

            stripLongText(text) {
                if (text) {
                    if (text.length > 100) {
                        return text.substring(0, 100) + '...';
                    } else {
                        return text;
                    }
                }
                return '';
            },
            modifyPoint() {
                this.isLoading = true;
                this.error = false;
                var json = this.json.replace(/\s/g, '');
                if (!json){
                    this.isLoading = false                                                                                     ;
                    return false;
                }

                let trailID = this.$route.params.id;
                let data = {
                    json: "{" + json + "}",
                    trailID: trailID,
                    type: 'bus'
                };
                LocationApi.addPointsByTrail(this, data, (context) => {
                    this.isLoading = false;
                    if (context.success) {
                        this.close();
                        this.$emit('show-create-success-alert');
                        this.getList();
                    }
                })

            },
            resetData() {
                this.json = null;


            },
            close() {

                this.resetData();
                this.$emit('clear-id');
                this.error = false;
                this.showModal = false;
            },

            getList() {
                var trail = this.$route.params.id;
                LocationApi.getLocationByTrail(this, {
                    page: this.page,
                    pageSize: this.pageSize,
                    trail: trail,
                    type: 'bus'
                }, (context) => {

                    mapboxgl.accessToken = 'pk.eyJ1IjoibmFudHVja2V0IiwiYSI6ImNsdndlaDNoYzI3MDkycnFpcTF6NHY3N28ifQ.li7SqKrAmbmtGDfo4bOIvw';
                    var map = new mapboxgl.Map({
                        container: 'map',
                        style: 'mapbox://styles/mapbox/streets-v11',
                        center: [0, 0],

                        zoom: 15
                    });
                    var args = [];
                    var coords = [];
                    var coordinates = "coordinates=";
                    if (context.locations.length > 0) {


                        context.locations.forEach(function(element, k) {
                            if (k != context.locations.length - 1)
                                coordinates = coordinates + element.long + '%2C' + element.lat + '%3B';
                            else {
                                coordinates = coordinates + element.long + '%2C' + element.lat;
                            }
                            var point = [element.long, element.lat];

                            args.push(point);

                        })

                        map.flyTo({
                            //center: [-122.48369693756104, 37.83381888486939],
                            center: args[0]
                        });
                        // var map = new mapboxgl.Map({
                        //     container: 'map',
                        //     style: 'mapbox://styles/mapbox/streets-v11',
                        //     center: args[0],
                        //     zoom: 15
                        // });




                        map.on('load', function() {

                             args.forEach(function(k, v) {



                                    new mapboxgl.Marker({color: 'red'})
                                        .setLngLat(k)
                                        .addTo(map);

                            });




                                 map.addLayer({
                                "id": "route",
                                "type": "line",
                                "source": {
                                    "type": "geojson",
                                    "data": {
                                        "type": "Feature",
                                        "properties": {},
                                        "geometry": {
                                            "type": "LineString",
                                            "coordinates": args
                                        }
                                    }
                                },
                                "layout": {
                                    "line-join": "round",
                                    "line-cap": "round"
                                },
                                "paint": {
                                    "line-color": "#ff0000",
                                    "line-width": 5
                                }
                            });
                            // LocationApi.getMapRedirect(this, {
                            //     coordinates: coordinates

                            // }, (context) => {

                            //     if (this.points.length > 0) {
                            //         this.points.forEach(function(element, k) {

                            //             coords.push(element.location);
                            //         })
                            //     }
                            //     console.log(coords);

                            //      map.addLayer({
                            //     "id": "route",
                            //     "type": "line",
                            //     "source": {
                            //         "type": "geojson",
                            //         "data": {
                            //             "type": "Feature",
                            //             "properties": {},
                            //             "geometry": {
                            //                 "type": "LineString",
                            //                 "coordinates": coords
                            //             }
                            //         }
                            //     },
                            //     "layout": {
                            //         "line-join": "round",
                            //         "line-cap": "round"
                            //     },
                            //     "paint": {
                            //         "line-color": "#888",
                            //         "line-width": 8
                            //     }
                            // });
                            // });


                         });



                        // console.log(map);
                        // console.log('1111:' + directions);
                        // if (typeof directions !== 'undefined') {
                        //     // your code here
                        //     unset(directions);
                        // }
                        // console.log('2222:' + directions);
                        // var directions = new MapboxDirections({
                        //     accessToken: mapboxgl.accessToken,
                        //     unit: 'metric',
                        //     profile: 'mapbox/walking'
                        // });

                       // map.on('load', function() {
                            // directions.setOrigin(args[0]);
                            // args.forEach(function(k, v) {
                            //     if ((v != 0) && (v != (args.length - 1))) {
                            //         directions.addWaypoint((v - 1), k);

                            //         new mapboxgl.Marker()
                            //             .setLngLat(k)
                            //             .addTo(map);
                            //     }
                            // });
                            // directions.setDestination(args[args.length - 1]);



                            // map.addControl(directions);


                      //  });


                    }



                })
            },
        }
    }
</script>
 