import auth from "../authentication";
import Vue from "../main";

export default {
  getReport(context, data, callback) {
    Vue.http
      .post(Vue.apiUrl + "/report/get-all", data)
      .then((response) => {
        context.error = false;
        context.report = response.data.data;
        context.total = response.data.total;
        callback(context);
      })
      .catch((response) => {
        context.error = true;
        context.message = response.data.message;
        context.total = 0;
        callback(context);
      });
  },

  getReportDetail(context, id, callback) {
    Vue.http
      .post(Vue.apiUrl + "/report/get-detail/" + id)
      .then((response) => {
        context.error = false;
        //context.message = response.data.message;
        context.report = response.data.data;
        callback(context);
      })
      .catch((response) => {
        context.error = true;
        context.message = response.data.message;
        callback(context);
      });
  },
};
