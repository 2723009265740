<template>
  <div class="m-grid__item m-grid__item--fluid m-wrapper">
    <div class="m-subheader">
      <div class="d-flex align-items-center">
        <div class="mr-auto">
          <h3 class="m-subheader__title m-subheader__title--separator">
            Report
          </h3>
          <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
            <li class="m-nav__item m-nav__item--home">
              <router-link
                exact
                :to="{ name: 'home' }"
                class="m-nav__link m-nav__link--icon"
              >
                <i class="m-nav__link-icon la la-home"></i>
              </router-link>
            </li>
            <li class="m-nav__separator">-</li>
            <li class="m-nav__item">
              <router-link exact :to="{ name: 'report' }" class="m-nav__link"
                ><span class="m-nav__link-text">Report</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="m-content">
      <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__head">
          <div class="m-portlet__head-caption">
            <div class="m-portlet__head-title">
              <h3 class="m-portlet__head-text">
                Report table
                <small> List of all report </small>
              </h3>
            </div>
          </div>
          <div class="m-portlet__head-tools"></div>
        </div>
        <div class="m-portlet__body">
          <!--begin: Search Form -->
          <div
            class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30"
          >
            <div class="row align-items-center">
              <div class="col-xl-8 order-2 order-xl-1">
                <div class="form-group m-form__group row align-items-center">
                  <div class="col-md-4">
                    <div class="m-input-icon m-input-icon--left">
                      <input
                        type="text"
                        class="form-control m-input m-input--solid"
                        v-model="searchKeyword"
                        @keyup="searchData"
                        placeholder="Search..."
                        id="generalSearch"
                      />
                      <span class="m-input-icon__icon m-input-icon__icon--left">
                        <span>
                          <i class="la la-search"></i>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end: Search Form -->
          <div class="table-responsive">
            <table class="table table-inverse" v-if="report.length > 0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Message</th>
                  <th>Type</th>
                  <th>Trail</th>
                  <th>Reporter</th>
                  <th>Image</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in filteredReport">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.message }}</td>
                  <td>{{ item.type.name }}</td>
                  <td>{{ item.trail.name }}</td>
                  <td>
                    <span v-if="item.reporter && item.reporter.name"
                      >Name: {{ item.reporter.name }}</span
                    >
                    <br />
                    <span v-if="item.reporter && item.reporter.phone_number"
                      >Phone no: {{ item.reporter.phone_number }}</span
                    >
                    <br />
                    <span v-if="item.reporter && item.reporter.email"
                      >Email: {{ item.reporter.email }}</span
                    >
                    <br />
                  </td>
                  <td>
                    <report-image-preview 
                        v-if="item.image"
                        v-bind:gallery="JSON.parse(item.image)"
                    />
                  </td>
                  <td>
                    <div v-if="item.lat && item.long">
                      <router-link
                        :to="{ name: 'reportDetail', params: { id: item.id } }"
                      >
                        <i class="la la-map"></i>
                      </router-link>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <b-pagination
            v-if="total > 0 && total > pageSize"
            align="right"
            size="md"
            :total-rows="total"
            v-model="page"
            :per-page="pageSize"
          >
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportApi from "../../api/report";
import auth from "../../authentication";
import ReportImagePreview from "./ReportImagePreview";

export default {
  components: {
    ReportImagePreview
  },
  name: "report",
  data() {
    return {
      error: false,
      message: null,
      page: 1,
      total: 0,
      searchKeyword: "",
      pageSize: 15,
      reportId: null,
      report: [],
    };
  },
  computed: {
    filteredReport: function () {
      return this.report.filter((item) => {
        //if (item.message.match(this.search)) {
        return item;
        //}
      });
    },
  },
  watch: {
    page(val) {
      this.getList();
    },
    pageSize(val) {
      this.getList();
    },
  },
  beforeMount() {
    this.getList();
  },
  methods: {
    searchData() {
      this.page = 1;
      this.getList();
    },
    getList() {
      let data = {
        page: this.page,
        pageSize: this.pageSize,
        keyword: this.searchKeyword,
      };
      if (auth.user.profile.role == 5) {
        data.user_id = auth.user.profile.id;
      }
      ReportApi.getReport(this, data, (context) => {});
    },
    updatePageSize(val) {
      this.pageSize = parseInt(val);
    },
    clearId() {
      this.difficultyId = null;
      this.getList();
    },
  },
};
</script>

<style scoped>
.d-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
</style>
