<template>
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-subheader ">
            <div class="d-flex align-items-center">
                <div class="mr-auto">
                    <h3 class="m-subheader__title m-subheader__title--separator">
                        Bus
                    </h3>
                    <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                        <li class="m-nav__item m-nav__item--home">
                            <router-link exact :to="{ name: 'home' }" class="m-nav__link m-nav__link--icon">
                                <i class="m-nav__link-icon la la-home"></i>
                            </router-link>
                        </li>
                        <li class="m-nav__separator">
                            -
                        </li>
                        <li class="m-nav__item">
                            <router-link exact :to="{ name : 'bus' }" class="m-nav__link"><span class="m-nav__link-text">Bus</span>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="m-content">

            <div class="kt-portlet kt-portlet--height-fluid">
                <div class="kt-portlet__head">

                    <div class="kt-portlet__head-toolbar">
                        <ul class="nav nav-pills nav-pills-sm nav-pills-label nav-pills-bold" role="tablist">
                            <li class="nav-item">
                                <router-link :to="{ name: 'listBusAll', params: { id: busID }}" class="nav-link ">
                                    All
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'listBusPoint', params: { id: busID }}" class="nav-link ">
                                    Bus Stop
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'listBusRoute', params: { id: busID }}" class="nav-link ">
                                    Bus Route
                                </router-link>
                            </li>


                        </ul>
                    </div>
                </div>

            </div>



            <div class="m-portlet m-portlet--mobile">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">
                                BUS STOP
                                <small>
                                    Map of BUS STOP
                                </small>
                            </h3>
                        </div>
                    </div>
                    <div class="m-portlet__head-tools">
                    </div>
                </div>
                <div class="m-portlet__body">
                    <!--begin: Search Form -->
                    <div class="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
                        <div class="row align-items-center">

                        </div>
                    </div>
                    <div class="alert alert-success alert-dismissible" role="alert" v-if="alertCreated">
                        <button type="button" class="close" aria-label="Close" @click="alertCreated=false"></button> New bus created successfully
                    </div>
                    <div class="alert alert-success alert-dismissible" role="alert" v-if="alertUpdated">
                        <button type="button" class="close" aria-label="Close" @click="alertUpdated=false"></button> Bus updated successfully
                    </div>
                    <div id="map"></div>

                    <b-container>
                        <!--begin::Form-->
                        <form class="m-form m-form--label-align-right">
                            <div class="m-portlet__body">
                                <div class="m-form__content">
                                    <div class="m-alert m-alert--icon alert alert-danger" role="alert" v-if="error" id="m_form_1_msg">
                                        <div class="m-alert__icon">
                                            <i class="la la-warning"></i>
                                        </div>
                                        <div class="m-alert__text" v-html="message">
                                        </div>

                                    </div>
                                </div>
                                <div class="m-form__section m-form__section--first">
                                    <div class="form-group m-form__group row">
                                        <label class="col-lg-2 col-form-label">
                                            Input geojson
                                        </label>
                                        <div class="col-lg-9">
                                            <textarea class="form-control m-input" v-model="json" placeholder="..."></textarea>
                                            <input type="hidden" title="about-sub_title" required="required" v-model="busID" class="form-control m-input">
                                            <span class="m-form__help">-Please input geojson taken from page http://geojson.io/ as this following example:<br/>
"coordinates": [<br/>
          [<br/>
            106.69441223144531,<br/>
            10.77367815586651<br/>
          ],<br/>
          [<br/>
            106.69617176055907,<br/>
            10.774858602342633<br/>
          ],<br/>
          [<br/>
            106.69310331344604,<br/>
            10.7773881149201<br/>
          ]<br/>
        ]<br/>
-Must copy exactly the same structure as our sample data<br/>
-Each time submitting a new geojson, all the new points will added to the end of the current list<br/>
-For the purpose of correct display, please make sure all POIs' longitude and latitude are in the intended bus with points<br/>
-To see the the overview map, admin need to insert at least all the points and POI or either POI not-in-route to active the overview map</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>

                        <div class="col-xl-12 order-1 order-xl-2 m--align-right">

                            <button type="button" class="btn btn-brand " @click="modifyPoint">{{ 'Add new POI(s)'}}

                            </button>
                        </div>



                        <!--end::Form-->
                    </b-container>


                </div>


                <div class="table-responsive">
                <table class="table table-inverse" v-if="locations.length > 0">
                    <thead>
                    <tr>
                        <th>#</th>

                        <th>Name</th>
                        <th>Information</th>
                        <th>Coordinates</th>

                        <th>Bus</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="location,index in this.locations">
                        <td>{{index + 1}}</td>

                        <td>{{ location.name }}</td>
                        <td>{{ stripLongText(location.information) }}</td>
                        <td>lat: {{ location.lat}} <br> long: {{ location.long }}
                        </td>

                        <td>{{ location.bus.name }}</td>
                        <td>
                                <span class="m-badge m-badge--wide {activeClass}" v-bind:class="[ location.active ? 'm-badge--success' : 'm-badge--danger' ]">{{ location.active ? "Active" : "Inactive" }}
                                                </span>
                        </td>
                        <td style="width: 100px;">
                            <button title="Edit details" class="btn m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill" v-b-modal="'newLocationModal'" @click="updateLocation(location.id)">
                                <i class="la la-edit"></i>
                            </button>
                            <button title="Delete" @click="deleteLocation(location.id, location.name)" class="btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill">
                                <i class="la la-trash"></i>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
                </div>
                <b-pagination v-if="total > 0 && total > pageSize" align="right" size="md" :total-rows="total" v-model="page" :per-page="pageSize">
                </b-pagination>

            </div>

            <location-modal @clear-id="clearId" @clicked-reload="getList" @show-create-success-alert="showCreateSuccessAlert" @show-update-success-alert="showUpdateSuccessAlert" v-bind:routeId="routeId" />

            <loading :active.sync="isLoading"
                     :z-index="99999"
                     :can-cancel="false"
                     :is-full-page="true"></loading>
            <!-- <MglMap id="map-container" :accessToken="accessToken" :mapStyle="mapStyle" :center="coordinates" @load="onMapLoaded" /> -->
        </div>
    </div>
</template>

<style>
    #map {
        height: 500px;
        width: 100%;
    }
</style>


<script>
    import LocationApi from '../../api/route';
    import GalleryPreview from  "../property/PropertyPreview";
    import LocationModal from './StopModal';
    // Import component
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';

    export default {
        components: {
            LocationModal,
            GalleryPreview,
            Loading
        },
        mounted: function() {},
        name: "location",
        data() {
            return {
                fullPage: true,
                isLoading: false,
                error: false,
                alertCreated: false,
                alertUpdated: false,
                message: null,
                page: 1,
                total: 0,
                search: '',
                pageSize: 1500,
                routeId: null,
                locations: [],
                json: '',
                busID: '',
                createdAlert: "New location created successfully",
                updatedAlert: "Location updated successfully"
            }
        },
        watch: {

        },
        beforeMount() {
            var id = this.$route.params.id;
            this.busID = id;
            this.getList()
        },
        methods: {
            modifyPoint() {
                this.isLoading = true;
                this.error = false;
                var json = this.json.replace(/\s/g, '');
                if (!json){
                    this.isLoading = false;
                    return false;
                }

                let busID = this.$route.params.id;
                let data = {
                    json: "{" + json + "}",
                    busID: busID,
                    type: 'bus'
                };
                LocationApi.addPointsByBus(this, data, (context) => {
                    this.isLoading = false;
                    if (context.success) {
                        this.close();
                        this.$emit('show-create-success-alert');
                        this.getList()
                    }
                })

            },
            resetData() {
                this.json = null;
            },
            close() {

                this.resetData();
                this.$emit('clear-id');
                this.error = false;
                this.showModal = false;
            },
            updateLocation(id) {

                this.routeId = id;
                this.$bvModal.show('newLocationModal');

            },
            deleteLocation(id, name) {
                let approve = confirm(`Do you want to remove POI: ${name} ?`);

                if (approve !== false) {
                    LocationApi.removeLocation(this, id, (context) => {

                        this.getList();
                        this.alertCreated = false;
                        this.alertUpdated = false;
                    });
                }
            },
            updatePageSize(val) {
                this.pageSize = parseInt(val);
            },
            clearId() {
                this.routeId = null;
                this.getList();
            },
            showCreateSuccessAlert() {
                this.alertCreated = true;
                this.alertUpdated = false;
            },
            showUpdateSuccessAlert() {
                this.alertCreated = false;
                this.alertUpdated = true;
            },

            stripLongText(text) {
                if (text) {
                    if (text.length > 100) {
                        return text.substring(0, 100) + '...';
                    } else {
                        return text;
                    }
                }
                return '';
            },

            getList() {
                var bus = this.$route.params.id;
                LocationApi.getLocationByBus(this, {
                    page: this.page,
                    pageSize: this.pageSize,
                    bus: bus,
                }, (context) => {

                    mapboxgl.accessToken = 'pk.eyJ1IjoibmFudHVja2V0IiwiYSI6ImNsdndlaDNoYzI3MDkycnFpcTF6NHY3N28ifQ.li7SqKrAmbmtGDfo4bOIvw';
                    var map = new mapboxgl.Map({
                        container: 'map',
                        style: 'mapbox://styles/mapbox/streets-v11',
                        center:[0,0],
                        zoom: 15
                    });
                    var args = [];
                    let color = '#b3b3b3';
                    if (context.locations.length > 0) {
                        context.locations.forEach(element => {
                            //color = element.bus.color ? element.bus.color : 'blue';
                            var point = [element.long, element.lat];
                            args.push(point);
                        })



                        map.flyTo({
                            center: args[0]
                        });



                        map.on('load', function() {
                            args.forEach(function(k, v) {
                                var _data = context.locations[v];
                                if (_data.icon) {
                                    let iconInfo = JSON.parse(_data.icon);
                                    var previewIcon = apiUrl + '/assets' + iconInfo.path;
                                    var el = document.createElement('div');
                                    el.className = 'marker';
                                    el.style.backgroundImage = 'url(' + previewIcon + ')';
                                    el.style.width = "30px";
                                    el.style.height = "30px";
                                    el.style.backgroundSize = "contain";
                                    new mapboxgl.Marker(el)
                                        .setLngLat(k)
                                        .addTo(map);
                                } else {
                                    new mapboxgl.Marker({color: color})
                                        .setLngLat(k)
                                        .addTo(map);
                                }
                            });
                        });


                    }



                })
            },
        }
    }
</script>
 