<template>
    <div class="m-grid__item m-grid__item--fluid m-wrapper">
        <div class="m-subheader ">
            <div class="d-flex align-items-center">
                <div class="mr-auto">
                    <h3 class="m-subheader__title m-subheader__title--separator">
                        Report
                    </h3>
                    <ul class="m-subheader__breadcrumbs m-nav m-nav--inline">
                        <li class="m-nav__item m-nav__item--home">
                            <router-link exact :to="{ name: 'home' }" class="m-nav__link m-nav__link--icon">
                                <i class="m-nav__link-icon la la-home"></i>
                            </router-link>
                        </li>
                        <li class="m-nav__separator">
                            -
                        </li>
                        <li class="m-nav__item">
                            <router-link exact :to="{ name : 'trail' }" class="m-nav__link"><span class="m-nav__link-text">Report</span>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    
    
        <div class="m-content">
    
            <div class="m-portlet m-portlet--mobile">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">
                                Map</small>
                            </h3>
                        </div>
                    </div>
                    <div class="m-portlet__head-tools">
                    </div>
                </div>
                <div class="m-portlet__body">
                    <div id="map"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
    #map {
        height: 500px;
        width: 100%;
    }
</style>
 

<script>
    import ReportApi from '../../api/report';
    import LocationApi from '../../api/location';
    export default {
        mounted: function() {},
        name: "reportDetail",
        data() {
            return {
                report: null,
                error: false,
                alertCreated: false,
                alertUpdated: false,
                message: null,
                page: 1,
                total: 0,
                search: '',
                pageSize: 10000,
                locationId: null,
                locations: [],
                poits: [],
                pois: [],
                notroutes: [],
                json: '',
                trailID: '',
            }
        },
        watch: {
    
    
        },
        beforeMount() {
            var id = this.$route.params.id;
            this.trailID = id;
            this.getDetail()
        },
        methods: {
          getDetail() {
            var id = this.$route.params.id;
            ReportApi.getReportDetail(this, id, (context) => {
              let lat = this.report.lat;
              let long = this.report.long;

              mapboxgl.accessToken = 'pk.eyJ1IjoibmFudHVja2V0IiwiYSI6ImNsdndlaDNoYzI3MDkycnFpcTF6NHY3N28ifQ.li7SqKrAmbmtGDfo4bOIvw';
              var map = new mapboxgl.Map({
                  container: 'map',
                  style: 'mapbox://styles/mapbox/streets-v11',
                  center: [long, lat],
                  zoom: 14
              });
              var args = [
                [long, lat]
              ];

              // map.flyTo({
              //   center: args[0]
              // });

              map.on('load', function() {
                args.forEach(function(k, v) {
                  new mapboxgl.Marker({ color: 'green' })
                      .setLngLat(k)
                      .addTo(map);
                });
              });
            });
          },
        }
    }
</script>
 