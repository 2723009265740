<template>
    <div class="d-flex">
        <template v-if="gallery">
            <div v-for="(img, index) in gallery" :key="index" class="mr-2">
                <vue-picture-swipe 
                    class="item-thumbnail" 
                    :items="getGalleryItem(img)"
                ></vue-picture-swipe>
            </div>
        </template>
    </div>
</template>

<script>
import VuePictureSwipe from 'vue-picture-swipe';

export default {
    components: {
        VuePictureSwipe
    },
    props: ['gallery'],
    methods: {
        getGalleryItem(image) {
            return [{
                src: apiUrl + '/assets' + image.path,
                thumbnail: apiUrl + '/assets' + image.path,
                w: 0,
                h: 0
            }];
        }
    }
}
</script>

<style lang="css" scoped>
.d-flex {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}
.item-thumbnail img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    cursor: pointer;
}
</style> 