<template>
    <!--begin::Modal-->
    <b-modal size="lg" id="newLocationModal" v-model="showModal"
             :title="locationId ? 'Update location' : 'Add new location'"
             @hidden="close">
        <b-container>
            <!--begin::Form-->
            <form class="m-form m-form--label-align-right">
                <div class="m-portlet__body">
                    <div class="m-form__content">
                        <div class="m-alert m-alert--icon alert alert-danger" role="alert" v-if="error"
                             id="m_form_1_msg">
                            <div class="m-alert__icon">
                                <i class="la la-warning"></i>
                            </div>
                            <div class="m-alert__text" v-html="message">
                            </div>
                            <div class="m-alert__close">
                                <button type="button" class="close" @click="error=false" aria-label="Close"></button>
                            </div>
                        </div>
                    </div>
                    <div class="m-form__section m-form__section--first">
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Name
                            </label>
                            <div class="col-lg-9">
                                <input type="text" required="required" v-model="name" class="form-control m-input">
                                <span class="m-form__help"> Please enter name </span>
                            </div>
                        </div>
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Information
                            </label>
                            <div class="col-lg-9">
                                <textarea class="form-control m-input" v-model="information"
                                          placeholder="..." maxlength="250"></textarea>
                                <span class="m-form__help">Please enter information (max 250 characters)</span>
                            </div>
                        </div>
                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Lat
                            </label>
                            <div class="col-lg-9">
                                <input type="number" step="any" class="form-control m-input" v-model="lat"/>
                                <span class="m-form__help">Please enter lat</span>
                            </div>
                        </div>

                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Long
                            </label>
                            <div class="col-lg-9">
                                <input type="number" step="any" class="form-control m-input" v-model="long"/>
                                <span class="m-form__help">Please enter long</span>
                            </div>
                        </div>

                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Starting Point
                            </label>
                            <div class="col-lg-9">
                                <span class="m-switch m-switch&#45;&#45;outline m-switch&#45;&#45;icon m-switch&#45;&#45;brand">
                                    <label>
                                        <input type="checkbox" v-model="isParking"
                                            :value="isParking">
                                        <span></span>
                                    </label>
                                </span>
                            </div>
                        </div>

                        <!-- <trail-select
                                @selectTrail="updateTrail"
                                v-bind:selectedTrail="currentTrail"
                        /> -->

                        <!-- <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Order
                            </label>
                            <div class="col-lg-9">
                                <input type="number" :disabled="notInRoute" class="form-control m-input"
                                       v-model="order"/>
                                <span class="m-form__help">Please enter order</span>
                            </div>
                        </div> -->


                        <!-- <div class="form-group m-form__group row">
                            <label for="is-poi" class="col-lg-2 col-form-label">
                                Is POI?
                            </label>
                            <div class="col-lg-9">
                                <input type="checkbox" id="is-poi" v-model="isPOI"/>
                            </div>
                        </div>

                        <div v-if="isPOI" class="form-group m-form__group row">
                            <label for="not-in-route" class="col-lg-2 col-form-label">
                                Not in route?
                            </label>
                            <div class="col-lg-9">
                                <input type="checkbox" id="not-in-route" v-model="notInRoute"/>
                            </div>
                        </div> -->


                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Audio
                            </label>
                            <div class="col-lg-8">
                                <input type="file" id="sound" name="sound" class="form-control m-input"
                                       v-on:change="handleFile('sound')"/>
                                <span class="m-form__help">Add point Audio (replace if audio already exists)</span>
                            </div>
                            <div class="col-lg-1">
                                <a target="_blank" :href="this.sound">
                                    <i v-if="this.sound" class="fa fa-file-sound-o"></i>
                                </a>
                            </div>
                        </div>


                        <div  class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Icon (preferred size: 128x128 and .png transparent background)
                            </label>
                            <div class="col-lg-8">
                                <input type="file" id="icon" name="icon" class="form-control m-input"
                                       v-on:change="handleFile('icon')"/>
                                <span class="m-form__help">Add point icon (replace if icon already exists)</span>
                            </div>
                            <div class="col-lg-1">
                                <a target="_blank" :href="this.previewIcon">
                                    <img v-if="this.previewIcon" width="40" height="40" alt="point-icon"
                                         v-bind:src='this.previewIcon'/>
                                </a>
                            </div>
                        </div>

                        <div class="form-group m-form__group row">
                            <label class="col-lg-2 col-form-label">
                                Gallery (preferred size: 1200x900)
                            </label>
                            <div class="col-lg-9">
                                <vue-dropzone v-model="gallery" class="m-dropzone m-dropzone--primary"
                                              ref="locationGalleryDropzone"
                                              id="locationDropzone"
                                              :options="dropOptions" :useCustomSlot=true
                                              @vdropzone-complete="afterDropzoneComplete"
                                              @vdropzone-removed-file="afterDropzoneRemove">
                                    <div class="m-dropzone__msg dz-message needsclick">
                                        <h3 class="m-dropzone__msg-title">
                                            Drop file here or click to upload.
                                        </h3>
                                        <span class="m-dropzone__msg-desc">
                                            Only image files are allowed for upload
                                        </span>
                                    </div>
                                </vue-dropzone>
                                <span style="color: red" class="m-form__help">You have to re-upload all images if want to update gallery!</span>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <!--end::Form-->
        </b-container>
        <div slot="modal-footer">
            <button type="button" class="btn btn-secondary" @click="showModal=false">Close</button>
            <button type="button" class="btn btn-brand" @click="modifyLocation">{{ locationId ? 'Update' : 'Create'}}
            </button>
        </div>
    </b-modal>
    <!--end::Modal-->
</template>

<script>
    import LocationApi from '../../api/location';
    import vueDropzone from 'vue2-dropzone'
    import Settings from '../../constant/settings';
    import TrailSelect from '../trail/TrailSelect';
    import FileHelper from '../../service/file';
    import PropertyPreview from '../property/PropertyPreview';

    export default {
        components: {
            TrailSelect,
            vueDropzone,
            PropertyPreview
        },
        name: "location-modal",
        props: ['locationId'],
        data() {
            return {
                oldGallery: null,
                success: false,
                error: false,
                message: null,
                showModal: false,
                id: this.locationId,
                name: null,
                information: null,
                lat: null,
                long: null,
                order: null,
                trailId: null,
                currentTrail: null,
                gallery: null,
                updateGallery: [],
                icon: null,
                previewIcon: null,
                fileHelper: FileHelper,
                notInRoute: false,
                isPOI: false,
                sound: null,
                isParking: false,
                dropOptions: {
                    thumbnailHeight: 120,
                    thumbnailWidth: 120,
                    url: Settings.imageDropzoneUrl,
                    maxFilesize: 2, // MB
                    maxFiles: 10,
                    addRemoveLinks: true,
                    acceptedFiles: "image/*",
                }
            }
        },
        watch: {
            locationId(val) {
                if (val) {
                    LocationApi.getLocationDetail(this, val, (context) => {
                        if (context.location) {
                            this.name = context.location.name;
                        this.sound = context.location.sound;
                            this.information = context.location.information;
                            this.lat = context.location.lat;
                            this.long = context.location.long;
                            this.trailId = context.location.trail_id;
                            this.currentTrail = context.location.trail_id;
                            this.distance = context.location.distance;
                            //this.gallery = context.location.gallery;
                            this.order = context.location.order;
                            this.icon = context.location.icon;
                            this.isPOI = !!+context.location.is_poi;
                            this.notInRoute = !!+context.location.not_in_route;
                        this.oldGallery = context.location.gallery;

                            this.isParking = !!+context.location.is_parking;

                            if (context.location.icon) {
                                let iconInfo = JSON.parse(context.location.icon);
                                this.previewIcon = apiUrl + '/assets' + iconInfo.path;
                            }else{
                                this.previewIcon = '';
                            }

                        if (context.location.sound) {
                            this.sound = apiUrl + '/assets' + JSON.parse(context.location.sound).path;
                        }

                            if (context.location.gallery) {
                                let gallery = JSON.parse(context.location.gallery);
                                gallery.images.forEach((image) => {
                                    let url = apiUrl + '/assets' + image.path;
                                    this.$refs.locationGalleryDropzone.manuallyAddFile(image, url);
                                });
                            }
                        }
                    })
                }
            },
        },
        methods: {
            modifyLocation() {
                this.error = false;
                let data = {
                    name: this.name,
                    information: this.information,
                    lat: this.lat,
                    long: this.long,
                    order: this.order,
                    trail_id: this.trailId,
                    gallery: this.gallery,
                    icon: this.icon,
                    is_poi: this.isPOI,
                    not_in_route: this.notInRoute,
                    sound: this.sound,

                };


                if (this.locationId) {

                    Object.keys(data).forEach(item => {
                        if (!data[item] || (data[item] && typeof (data[item]) === 'string' && data[item].includes('uploads'))) {
                        delete (data[item]);
                    }
                });

                    data.oldGallery = this.oldGallery;
                    data['is_parking'] = this.isParking;
                    LocationApi.updateLocation(this, this.locationId, data, (context) => {
                        if (context.success) {
                            this.close();
                            this.$emit('show-update-success-alert');
                        }
                    })
                } else {
                    data['is_parking'] = this.isParking;
                    LocationApi.createLocation(this, data, (context) => {
                        if (context.success) {
                            this.close();
                            this.$emit('show-create-success-alert');
                        }
                    })
                }
            },
            resetData() {
                this.oldGallery = null;
                this.id = null;
                this.name = null;
                this.gallery = null;
                this.lat = null;
                this.long = null;
                this.order = null;
                this.trailId = null;
                this.information = null;
                this.currentTrail = null;
                this.icon = null;
                this.notInRoute = false;
                this.isPOI = false;
                this.$refs.locationGalleryDropzone.removeAllFiles();
                this.sound = null;
                this.isParking = false;
                document.querySelector('#icon').value = '';
                document.querySelector('#sound').value = '';
            },
            close() {
                this.resetData();
                this.$emit('clear-id');
                this.error = false;
                this.showModal = false;
            },
            afterDropzoneComplete(file) {
                if (this.gallery) {
                    this.gallery.push(file.dataURL);
                } else {

                    if ((typeof file.manuallyAdded !== 'undefined' && file.manuallyAdded === true)) {

                    } else {
                        if (typeof file !== 'undefined') {
                            this.gallery = [];
                            this.gallery.push(file.dataURL);
                        }
                    }
                }
            },
            updateTrail(id) {
                this.trailId = id;
            },

            handleFile(id) {
                let file = document.querySelector('#' + id);
                if (typeof file.files[0] != 'undefined') {
                    this.fileHelper.getBase64(file.files[0], this, (context) => {
                        if (context) {
                            if (id === 'icon') {
                                this.icon = context.currentFile;

                            }
                            if(id === 'sound'){
                                this.sound = context.currentFile;
                            }
                            if (id !== 'icon' && id !== 'sound')  {
                                let file = document.querySelector('#' + id);
                                file.value = null;
                            }
                        }
                    });
                } else {
                    this.currentFile = null;
                }
            },
            afterDropzoneRemove(file) {
                if ((typeof file.manuallyAdded !== 'undefined' && file.manuallyAdded === true)) {
                    let removeFileName = file.name;
                    if (this.oldGallery) {
                        let oldGallery = JSON.parse(this.oldGallery).images;

                        oldGallery.forEach((image, index) => {
                            if (image.name === removeFileName) {
                            oldGallery.splice(index, 1);
                        }
                    });
                        if (oldGallery.length === 0) {
                            this.oldGallery = null;
                        } else {
                            this.oldGallery = JSON.stringify({'images': oldGallery});
                        }
                    }
                } else {
                    let index = this.gallery.indexOf(file.dataURL);
                    if (index > -1) {
                        this.gallery.splice(index, 1);
                    }
                }
            },
        }
    }
</script>